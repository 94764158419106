import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './App.css';
import Giveaway from './components/Giveaway';
import dayjs from 'dayjs';
import _ from 'lodash';
import TEAMS from './constants/teams';

/* SUPPORTED ROUTES:
  /        -- Default/First TEAM (Padres)
  /padres  -- Padres
  /dbacks  -- Diamondbacks
*/

const pathName = window.location && window.location.pathname,
      team = (pathName && _.find(TEAMS, (t) => ('/' + t.pathName) === pathName)) || TEAMS[0]; // pull team from the window pathname

function App() {
  let currentDay = dayjs(),
      previousDay = currentDay.add(-1, 'day'),
      formatString = 'YYYY-MM-DD',
      previousDayString = previousDay.format(formatString),
      currentDayString = currentDay.format(formatString),
      hostAPI = 'https://statsapi.mlb.com',
      scheduleAPI = '/api/v1/schedule/games/?sportId=1&startDate=' + previousDayString + '&endDate=' + currentDayString + '&teamId=' + team.id, // current games
      [game, setGame] = useState(null),
      [scheduledNext, setScheduledNext] = useState(null),
      [fetched, setFetched] = useState(false);

  useEffect(() => { // eslint-disable-line react-hooks/exhaustive-deps
    if(!fetched) {
      let date,
          today;
      setFetched(true);
      fetch(hostAPI + scheduleAPI)
        .then(res => res.json())
        .then((schedule) => {
          date = _.find(schedule && schedule.dates, (d) => { return(d.date === previousDayString) })
          today = _.find(schedule && schedule.dates, (d) => { return(d.date === currentDayString) })
          // gameType of 'R' denotes regular season game
          setScheduledNext(today && today.games && today.games[0].gameType === 'R' && today.games[0]);
          if(date && date.games.length > 0 && date.games[0].gameType) {// === 'R') {
            // TODO: just the first game for now, add double header logic tho
            fetch(hostAPI + date.games[0].link)
              .then(g => g.json())
              .then((gameResponse) => {
                setGame(gameResponse);
            })
          } else { // No Regular Season Games
            setGame(-1); // no game yesterday
          }
        })
    }
  });



  return (
   <React.Fragment>
    <Helmet>
      <title>{`freejumbojack.com - Did the ${team && team.name} earn ${team && team.demonym} a free Jumbo Jack?`}</title>
      <meta name="og:title" property="og:title" content={`Did the ${team && team.name} earn ${team && team.demonym} a free Jumbo Jack?`} />
      <meta name="description" content={`Curious if the ${team && team.name} earned you a free Jumbo Jack?  Check here every day to find out, and learn who hit the homer that will save you a couple of bucks.`} />
    </Helmet>
    <div className={"App Team-" + (team && team.pathName)}>
      { team.promotions.map((promotion) => {
        return(<Giveaway game={game} team={team} scheduledNext={scheduledNext} type={promotion} />);
      })}
    </div>
    <div className="footer">
      <span>&copy;2023 Not affiliated with Jack In the Box, Major League Baseball{ team && ', or the ' + team.fullName}</span>
      |
      <span><a href="mailto:ryan@jryky.com">contact</a></span>
    </div>
   </React.Fragment>
  );

}

export default App;
